import {AxiosInstance} from 'axios';
import {CustomException} from '@core/modules/common/infra/error';
import {httpMessage} from '@core/modules/common/infra/message';

import {Paths} from './keys';
import { CustomerGateway } from '../domain/customer.gateway';
import { UpdateCustomerInterface } from '@/@presentation/modules/schedule/pages/home/receiver';
import { SearchCustomerInterface } from '../domain/customer.entity';

export class CustomerHttpGateway implements CustomerGateway {
    constructor(private http: AxiosInstance) {}

    async create(params: UpdateCustomerInterface): Promise<number> {
        return this.http
            .post(Paths.default, {
                ...params,
                scheduleId: params.scheduleId
            })
            .then((res) => {
                if (!res?.data) {
                    throw new CustomException(httpMessage.updateResponseEmpty('create customer'));
                }
                return res?.data
            })
            .catch((error) => {
                if (error?.status !== 409) {
                    throw new CustomException(error?.response?.data);
                }
                throw new CustomException(httpMessage.updateError('create customer'));
            });
    }

    async update(params: UpdateCustomerInterface): Promise<number> {
        
        return this.http
            .put(Paths.default, params)
            .then((res) => {
                if (!res?.data) {
                    throw new CustomException(httpMessage.updateResponseEmpty('update customer'));
                }
                return res?.data ?? 0
            })
            .catch((error) => {
                if (error?.status !== 409) {
                    throw new CustomException(error?.response?.data);
                }
                return 0;
                throw new CustomException(httpMessage.updateError('update customer'));
            });
    }

    async search(cpf: string): Promise<SearchCustomerInterface> {
        return this.http
            .get(Paths.search + '/' + cpf.replace(/\D/g, ''))
            .then((res) => {
                if (!res?.data) {
                    throw new CustomException(httpMessage.updateResponseEmpty('search customer'));
                }
                return res?.data ?? 0
            })
            .catch((error) => {
                if (error?.status !== 409) {
                    throw new CustomException(error?.response?.data);
                }
                throw new CustomException(httpMessage.updateError('search customer'));
            });
    }
}
