import { IScheduleGeneralItens } from '../domain/procedure.entities';
import {ProcedureGateway} from '../domain/procedure.gateway';

export class CreateUseCase {
    constructor(private gate: ProcedureGateway) {}

    async execute(params: any): Promise<any> {
         const data = await this.gate.createProcedure(params);
         return data;
    }

    async loadSearchGeneral(id?: string): Promise<IScheduleGeneralItens> {
        return this.gate.loadSearchGeneral(id);
    }

    scheduleDateValidationOnCreate(date: Date) {
        const now = new Date();
        const errorResponse = `
        Atenção,
        devido à proximidade de realização deste procedimento,
        o pedido deverá ser feito exclusivamente por contato telefônico diretamente para o nosso escalador no telefone:
        11-99434-9850
        `;

        const minDateTime = new Date();
        minDateTime.setDate(date.getDate() - 1);
        minDateTime.setMonth(date.getMonth());
        minDateTime.setHours(17, 0, 0, 0);

        if (now > minDateTime) {
            return {
                isError: true,
                text: errorResponse,
            }
        }

        return {
            isError: false,
            text: '',
        };
    }
}
