import React from "react";
import { Row, Typography, Col } from 'antd';
import { BarChart, Bar, XAxis, Tooltip, ResponsiveContainer } from 'recharts';
import ChartIcon from '../../../../../public/icons/charts.svg';
import { IChartNextDays } from "@/@core/modules/dashboard/domain/dashboard.entities";
import { Button } from "@/@presentation/components/form";

interface IProps {
    data: IChartNextDays[];
    title: string;
    onClick?: () => void;
}

const CustomBar = (props: any) => {
    const { x, y, width, height } = props;

    return (
        <g>
            <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#004080" stopOpacity={1} />
                    <stop offset="95%" stopColor="#00BFFF" stopOpacity={1} />
                </linearGradient>
            </defs>
            <rect x={x} y={y} width={width} height={height} fill="url(#colorUv)" />
        </g>
    );
};

export const CardWithChart: React.FC<IProps> = (props: IProps) => {
    const total = props.data.reduce((acc, item) => acc + item.total, 0);

    // Calcular a porcentagem de largura para a coluna do gráfico
    const dataLength = props.data.length;
    const chartColSpan = Math.min(6, Math.max(3, Math.floor(dataLength * 1)));
    const textColSpan = 12 - chartColSpan;

    return (
        <div
            style={{
                backgroundColor: 'white',
                borderRadius: 8,
                boxShadow: '7px 3px 17px 3px rgba(0, 0, 0, 0.04)',
                padding: 16,
                marginLeft: 8,
                width: 'calc(100% - 32px)',
            }}
        >
            <Row justify="space-between" align="middle">
                <Col span={textColSpan}>
                    <Row style={{ marginBottom: 5 }}>
                        <img src={ChartIcon} style={{ width: 40, height: 40 }} />
                    </Row>
                    <Row>
                        <Typography.Text style={{ fontSize: 16, fontWeight: 400 }}>{props.title}</Typography.Text>
                    </Row>
                    <Row>
                        <Typography.Text style={{ color: '#1BA7CE', fontSize: 28, fontWeight: 500 }}>{total}</Typography.Text>
                    </Row>
                    <Row>
                        <Button
                            type="primary"
                            nativeProps={{ ghost: true, style: { marginRight: 16, marginTop: 15 } }}
                            text="Ver detalhes"
                            onClick={props.onClick}
                        />
                    </Row>
                </Col>
                <Col span={chartColSpan}>
                    <ResponsiveContainer width="100%" height={200} style={{ marginTop: 30 }}>
                        <BarChart data={props.data}>
                            <XAxis
                                dataKey="date"
                                tickFormatter={(value) => `${value.split('/').slice(0, 2).join('/')}`}
                                tickLine={false}
                                axisLine={false}
                            />
                            <Tooltip />
                            <Bar dataKey="total" shape={<CustomBar />} />
                        </BarChart>
                    </ResponsiveContainer>
                </Col>
            </Row>
        </div>
    );
};
