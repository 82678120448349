import {AxiosInstance} from 'axios';
import MockAdapter from 'axios-mock-adapter';
import {CustomException} from '@core/modules/common/infra/error';
import {httpMessage} from '@core/modules/common/infra/message';
import {
    Surgeon,
    TGet,
    SurgeonPagination,
    TAll,
    TResetPassword,
    TCreateOrUpdate,
} from '../domain/surgeon.entities';
import {SurgeonGateway} from '../domain/surgeon.gateway';
import {Paths} from './keys';
import {resetPasswordMock} from '../__mocks__/surgeon';

export class SurgeonHttpGateway implements SurgeonGateway {
    constructor(private http: AxiosInstance, private mock: MockAdapter) {}

    async get(params: TGet): Promise<Surgeon> {
        return this.http
            .get(`${Paths.default}/${params.id}`)
            .then((res) => {
                if (!res?.data) {
                    throw new CustomException(httpMessage.getAllResponseEmpty('cirurgião'));
                }
                return new Surgeon(res.data);
            })
            .catch((error) => {
                if (error?.status !== 409) {
                    throw new CustomException(error?.response?.data);
                }
                throw new CustomException(httpMessage.getAllError('o cirurgião'));
            });
    }

    async getAll(params: TAll): Promise<SurgeonPagination> {
        return this.http
            .get(Paths.default, {params: params})
            .then((res) => {
                if (!res?.data) {
                    throw new CustomException(httpMessage.getAllResponseEmpty('cirurgiões'));
                }

                return new SurgeonPagination(res.data);
            })
            .catch((error) => {
                if (error?.status !== 409) {
                    throw new CustomException(error?.response?.data);
                }
                throw new CustomException(httpMessage.getAllError('os cirurgiões'));
            });
    }

    async create(params: TCreateOrUpdate): Promise<Surgeon> {
        return this.http
            .post(Paths.default, params)
            .then((res) => {
                if (!res?.data) {
                    throw new CustomException(httpMessage.addResponseEmpty('cirurgião'));
                }
                return new Surgeon(res?.data);
            })
            .catch((error) => {
                if (error?.status !== 409) {
                    throw new CustomException(error?.response?.data);
                }
                throw new CustomException(httpMessage.addError('o cirurgião'));
            });
    }

    async createAccess(params: TGet): Promise<Surgeon> {
        return this.http
            .post(Paths.access, {surgeonId: params.id})
            .then((res) => {
                if (!res?.data) {
                    throw new CustomException(httpMessage.addResponseEmpty('cirurgião'));
                }
                return new Surgeon(res?.data);
            })
            .catch((error) => {
                if (error?.status !== 409) {
                    throw new CustomException(error?.response?.data);
                }
                throw new CustomException(httpMessage.addError('o cirurgião'));
            });
    }

    async update(params: TCreateOrUpdate): Promise<Surgeon> {
        return this.http
            .put(Paths.default, params)
            .then((res) => {
                if (!res?.data) {
                    throw new CustomException(httpMessage.updateResponseEmpty('cirurgião'));
                }
                return new Surgeon(res?.data);
            })
            .catch((error) => {
                if (error?.status !== 409) {
                    throw new CustomException(error?.response?.data);
                }
                throw new CustomException(httpMessage.updateError('o cirurgião'));
            });
    }

    async resetPassword(params: TResetPassword): Promise<boolean> {
        const path = `${Paths.default}/password/reset`;

        resetPasswordMock(this.mock, path);
        return this.http
            .post(path, params)
            .then((res) => {
                if (res?.status === 200) {
                    return true;
                }
                throw new Error();
            })
            .catch(() => {
                throw new CustomException(`Error ao tentar alterar a senha do cirurgião.`);
            });
    }
}
