import 'reflect-metadata';
import {Container} from 'inversify';
import {httpApi} from '../../common/infra/http';
import { CustomerHttpGateway } from './customer.http.gateway';
import { UpdateUseCase } from '../application/update.use.case';
import { CreateUseCase } from '../application/create.use.case';
import { SearchUseCase } from '../application/search.use.case';

export const Registry = {
    AxiosAdapter: Symbol.for('AxiosAdapter'),
    CustomerGateway: Symbol.for('CustomerGateway'),
    UpdateUseCase: Symbol.for('UpdateUseCase'),
    CreateUseCase: Symbol.for('CreateUseCase'),
    SearchUseCase: Symbol.for('SearchUseCase'),
};

export const container = new Container();

//########## HTTP
container.bind(Registry.AxiosAdapter).toConstantValue(httpApi);

//#region ########## GATEWAYS
container.bind(Registry.CustomerGateway).toDynamicValue((context) => {
    return new CustomerHttpGateway(context.container.get(Registry.AxiosAdapter));
});

//#endregion

//#region ########## USE CASES
container.bind(Registry.CreateUseCase).toDynamicValue((context) => {
    return new CreateUseCase(context.container.get(Registry.CustomerGateway));
});

container.bind(Registry.UpdateUseCase).toDynamicValue((context) => {
    return new UpdateUseCase(context.container.get(Registry.CustomerGateway));
});

container.bind(Registry.SearchUseCase).toDynamicValue((context) => {
    return new SearchUseCase(context.container.get(Registry.CustomerGateway));
});
//#endregion

//#region ######### CONTAINERS GET
const customer = {
    create: container.get<CreateUseCase>(Registry.CreateUseCase),
    update: container.get<UpdateUseCase>(Registry.UpdateUseCase),
    search: container.get<SearchUseCase>(Registry.SearchUseCase),
};
export default customer;
//#endregion
