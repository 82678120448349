import React, { useEffect, useState } from 'react';
import { Table, Layout, Empty, Row, Col } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Notification } from '@presentation/components/common';
import { Pagination, Filter, OptionType, Button } from '@presentation/components/form';

import style from './style';
import { TableRowSelection } from 'antd/es/table/interface';
import FiltersBase from '@/@presentation/modules/schedule/pages/home/FiltersBase';
import { useItemsContext } from '@/@presentation/contexts/schedulesContext';

export interface DataType {
    key: React.Key;
    name: string;
    id: number;
}

export interface ILoadData {
    offset: number;
    limit: number;
    selectedKey: string | undefined;
    value: string | undefined;
    dtIni?: string;
    dtFim?: string;
    search?: string;
    schedule_id?: number;
    status?: string;
    patient?: string;
    requester?: string;
    surgeon_ids?: string;
    unity_ids?: string;
    statusBilling?: string;
}

interface IList {
    columns: ColumnsType<DataType | any>;
    loadData?: (props: ILoadData) => Promise<any>;
    options?: OptionType;
    Form?: (props: any) => JSX.Element;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    loading: boolean;
    setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    modalVisible: boolean;
    setParams: React.Dispatch<any>;
    params: any;
    isReload: boolean;
    setReload: React.Dispatch<boolean>;
    headerChildren?: React.ReactElement;
    isAdd?: boolean;
    onAdd?: () => void | undefined;
    rowSelection?: TableRowSelection<any> | undefined;
    onRow?: (record: any, rowIndex: any) => void;
    hideDataFilter?: boolean;
    showStatusFilter?: boolean;
    showStatusBillingFilter?: boolean;
    selectedKeyView?: any;
}

const BaseList = (props: IList) => {
    const {
        initialDate,
        finalDate,
        selectedKey,
        setSelectedKey,
        statusFilter,
        scheduleId,
        locale,
        requester,
        surgeon,
        patient,
        createdInitial,
        createdFinal,
        statusBilling,
    } = useItemsContext();

    const { currentPagination, setCurrentPagination } = useItemsContext();

    const [dataRaw, setDataRaw] = useState<any>([]);
    const [data, setData] = useState<any>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [total, setTotal] = useState(0);
    const [value, setValue] = useState<string>('');

    const [filter, setFilter] = useState('');

    async function loadData() {
        if (!props?.loadData || props.loading) return;
        try {
            props.setLoading(true);
            var schd_id;
            if (!isNaN(filter)) {
                schd_id = parseInt(filter);
                setFilter('');
            }

            let params: any = {
                offset: currentPage !== 0 ? (currentPage - 1) * pageSize : 0,
                limit: pageSize,
                selectedKey,
                value,
                dtIni: initialDate,
                dtFim: finalDate,
            };

            if (statusFilter !== '' && statusFilter !== undefined) {
                params.status = statusFilter;
            }

            if (locale !== '' && locale !== undefined) {
                params.unity_ids = locale;
            }

            if (scheduleId !== '' && scheduleId !== undefined) {
                params.schedule_id = scheduleId;
            }

            if (requester !== '' && requester !== undefined) {
                params.requester = requester;
            }

            if (surgeon !== '' && surgeon !== undefined) {
                params.surgeon_ids = surgeon;
            }

            if (patient !== '' && patient !== undefined) {
                params.patient = patient;
            }

            if (!isNaN(schd_id)) {
                params.schedule_id = schd_id;
            }

            if (filter !== '') {
                params.search = filter;
            }

            if (createdInitial !== '' && createdInitial !== undefined) {
                params.createdAt = createdInitial;
            }

            if (createdFinal !== '' && createdFinal !== undefined) {
                params.endCreatedAt = createdFinal;
            }

            if (statusBilling !== '' && statusBilling !== undefined) {
                params.statusBilling = statusBilling;
            }

            const response = await props?.loadData(params);

            if (response) {
                setTotal(response.pagination.total);
                setData(response.data);
                setDataRaw(response.data);
            } else {
                setTotal(0);
                setData([]);
                setDataRaw([]);
            }
        } catch (error: any) {
            Notification.error({
                message: error.message,
            });
        } finally {
            props.setLoading(false);
            props.setReload(false);
        }
    }

    const handleCancel = () => {
        if (props.loading) return;
        props.setModalVisible(false);
        props.setParams(undefined);
    };

    const handleSave = async () => {
        if (props.loading) return;
        props.setModalVisible(false);
        Notification.success({
            message: 'Salvo com sucesso!',
            duration: 2,
        });

        await loadData();
    };

    useEffect(() => {
        loadData();

        if (currentPagination > 0) {
            setCurrentPage(currentPagination);
        }
    }, [currentPage, value, pageSize, props.isReload === true]);

    const onShowSizeChange = (current: number, size: number) => {
        if (props.loading) return;
        setCurrentPage(current);
        setCurrentPagination(current);
        setPageSize(size);
    };

    const onChange = (page: number, pageSize: number) => {
        if (props.loading) return;
        setCurrentPage(page);
        setCurrentPagination(page);
        setPageSize(pageSize);
    };

    const handleFilterChange = async (key: string, value: string) => {
        if (props.loading) return;
        setSelectedKey(key);
        setValue(value);
        setCurrentPage(0);
        setCurrentPagination(0);
        setPageSize(5);
    };

    useEffect(() => {
        loadData();
    }, [filter]);

    return (
        <Layout
            style={{
                backgroundColor: 'white',
                minWidth: '100%',
                maxWidth: '100%',
                overflow: 'hidden',
            }}
        >
            {props?.headerChildren}

            {props.hideDataFilter === true ? (
                <></>
            ) : (
                <FiltersBase
                    setFilter={setFilter}
                    dataRaw={dataRaw}
                    setReload={props.setReload}
                    showStatus={props.showStatusFilter ?? false}
                    showStatusBilling={props.showStatusBillingFilter ?? false}
                />
            )}

            <Row>
                <Col xl={12} xxl={12} xs={24} span={24}></Col>
                <Col xl={12} xxl={12} xs={24} span={24} style={{ textAlign: 'right' }}>
                    {props?.options && (
                        <Filter options={props.options} onFilterChange={handleFilterChange} />
                    )}
                </Col>
            </Row>

            <Row>
                <Col xl={12} xxl={12} xs={24} span={24}></Col>
                <Col xl={12} xxl={12} xs={24} span={24} style={{ textAlign: 'right' }}>
                    {props?.isAdd && props?.onAdd && (
                        <Button
                            text='Adicionar'
                            nativeProps={{
                                'data-testid': 'btn-add',
                                type: 'primary',
                                size: 'middle',
                                style: { margin: 10, marginTop: 20 },
                            }}
                            type="primary"
                            data-testid="btn-add"
                            size="middle"
                            onClick={props.onAdd}
                        />
                    )}
                </Col>
            </Row>

            {
                props.selectedKeyView && (
                    <Row>
                        <props.selectedKeyView />
                    </Row>
                )
            }

            <Layout style={style.containerTable}>
                <div
                // style={{
                //     overflow: 'auto', // @TODO SCROLL: Restore this for table scroll functionality
                //     maxHeight: '60vh', // @TODO SCROLL: Restore this for table scroll functionality
                // }}
                >
                    <Table
                        style={{ minWidth: '100%' }}
                        data-testid="table-form"
                        rowSelection={props?.rowSelection}
                        columns={props.columns}
                        dataSource={data}
                        pagination={false}
                        loading={props.loading}
                        size={'small'}
                        rowKey="id"
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: () => {
                                    if (props.onRow) {
                                        return props.onRow(record, rowIndex);
                                    }
                                },
                                style: { cursor: 'pointer' },
                            };
                        }}
                        locale={{
                            emptyText: (
                                <Empty
                                    description={<span>Não há dados</span>}
                                    style={{
                                        minHeight: '100%',
                                        minWidth: '100%',
                                    }}
                                />
                            ),
                        }}
                        scroll={{ x: '100%', y: '100%' }}
                    // scroll={{ x: '100%', y: '45vh' }} // @TODO SCROLL: Restore this for table scroll functionality
                    />
                </div>

                <Layout style={style.pagination}>
                    <Pagination
                        total={total}
                        defaultPageSize={pageSize}
                        defaultCurrent={1}
                        onShowSizeChange={onShowSizeChange}
                        onChange={onChange}
                        loading={props.loading}
                    />
                </Layout>
            </Layout>
            {props.Form && (
                <props.Form
                    params={props.params}
                    visible={props.modalVisible}
                    ocultarModal={handleCancel}
                    callback={handleSave}
                />
            )}
        </Layout>
    );
};

export const List = React.memo(BaseList);
