import {UserInfos} from '@/@core/modules/authentication/domain/userInfos.entities';
import {auth} from '@core/modules/authentication/infra/container.registry';

enum ENUM_PERMISSIONS {
    admin = 'CUSTOMER_PORTAL_ADMINISTRATIVE',
    secretary = 'CUSTOMER_PORTAL_SECRETARY',
    surgeon = 'CUSTOMER_PORTAL_SURGEON',
    conciliator = 'CUSTOMER_PORTAL_RECONCILIATOR',
}

enum ENUM_TYPES {
    backoffice = 'BACKOFFICE',
    secretary = 'SECRETARY',
    surgeon = 'SURGEON',
}

export async function isAdmin(): Promise<boolean> {
    const user = await auth.userInfos.execute();
    if (!user) return false;

    for (const profile of user.profiles) {
        const permissions = profile.permissions || [];
        for (const permission of permissions) {
            if (ENUM_PERMISSIONS.admin.includes(permission.key)) {
                return true;
            }
        }
    }
    return false;
}

export async function isSecretary(): Promise<boolean> {
    const user = await auth.userInfos.execute();
    if (!user) return false;

    for (const profile of user.profiles) {
        const permissions = profile.permissions || [];
        for (const permission of permissions) {
            if (ENUM_PERMISSIONS.secretary.includes(permission.key)) {
                return true;
            }
        }
    }
    return false;
}

export async function isSurgeon(): Promise<boolean> {
    const user = await auth.userInfos.execute();
    if (!user) return false;

    for (const profile of user.profiles) {
        const permissions = profile.permissions || [];
        for (const permission of permissions) {
            if (ENUM_PERMISSIONS.secretary.includes(permission.key)) {
                return true;
            }
        }
    }
    return false;
}

async function getTypeProfile(user: UserInfos): Promise<ENUM_PERMISSIONS | undefined> {
    if (!user) return undefined;

    if (ENUM_PERMISSIONS.admin.includes(user?.type)) {
        return ENUM_PERMISSIONS.admin;
    }
    if (ENUM_PERMISSIONS.surgeon.includes(user?.type)) {
        return ENUM_PERMISSIONS.surgeon;
    }
    if (ENUM_PERMISSIONS.secretary.includes(user?.type)) {
        return ENUM_PERMISSIONS.secretary;
    }
    return undefined;
}

async function getType(user: UserInfos): Promise<ENUM_TYPES | undefined> {
    if (!user) return undefined;

    if (ENUM_TYPES.backoffice.includes(user?.type)) {
        return ENUM_TYPES.backoffice;
    }
    if (ENUM_TYPES.surgeon.includes(user?.type)) {
        return ENUM_TYPES.surgeon;
    }
    if (ENUM_TYPES.secretary.includes(user?.type)) {
        return ENUM_TYPES.secretary;
    }
    return undefined;
}

export async function hasPermission(permissionKeys: string[]): Promise<boolean> {
    const user = await auth.userInfos.execute();
    if (!user) return false;

    for (const profile of user.profiles) {
        const permissions = profile.permissions || [];
        for (const permission of permissions) {
            if (permissionKeys.includes(permission.key)) {
                return true;
            }
        }
    }
    return false;
}

const paths = (paths: any) => {
    return paths?.filter((route: any) => route.label !== '');
};

const permittedRoles = (path: string) => {
    return path;
};

export {paths, permittedRoles, ENUM_PERMISSIONS, getTypeProfile, getType, ENUM_TYPES};
