import { IConciliator } from "@/@core/modules/conciliator/domain/conciliator.entity";
import { ColumnsType } from "antd/es/table";

export const columns: ColumnsType<IConciliator> = [
    {
        title: 'ID Conciliação',
        dataIndex: 'idConciliation',
        sortDirections: ['descend'],
        fixed: 'left',
        responsive: ['sm'],
    },
    {
        title: 'Nro. Primário Pedido',
        dataIndex: 'primaryOrderNumber',
        defaultSortOrder: 'descend',
        responsive: ['sm'],
    },
    {
        title: 'Nro. Primário NF',
        dataIndex: 'primaryInvoiceNumber',
        defaultSortOrder: 'descend',
        responsive: ['sm'],
    },
    {
        title: 'Data Aprovação',
        dataIndex: 'approvalDate',
        render: (value: string) => new Date(value).toLocaleDateString(),
        defaultSortOrder: 'descend',
        responsive: ['sm'],
    },
    {
        title: 'Valor Transação',
        dataIndex: 'transactionValue',
        defaultSortOrder: 'descend',
        responsive: ['sm'],
    },
    {
        title: 'Taxa aplicada',
        dataIndex: 'appliedFee',
        defaultSortOrder: 'descend',
        responsive: ['sm'],
    },
    {
        title: 'Valor Líquido',
        dataIndex: 'liquidValue',
        defaultSortOrder: 'descend',
        responsive: ['sm'],
    },
    {
        title: 'Nro. Parc.',
        dataIndex: 'installmentNumber',
        defaultSortOrder: 'descend',
        responsive: ['sm'],
    },
    {
        title: 'Chave VINDI',
        dataIndex: 'vindiKey',
        defaultSortOrder: 'descend',
        responsive: ['sm'],
    },
    {
        title: 'Tipo Pgto',
        dataIndex: 'paymentType',
        defaultSortOrder: 'descend',
        responsive: ['sm'],
    },
    {
        title: 'Status',
        dataIndex: 'status',
        defaultSortOrder: 'descend',
        responsive: ['sm'],
    },
    {
        title: 'Obs.',
        dataIndex: 'observation',
        defaultSortOrder: 'descend',
        responsive: ['sm'],
    }
];
