import { Button, Loading } from '@/@presentation/components/form';
import { Typography, Form as AntForm, Divider } from 'antd';
import UploadComponent, { UploadedType } from '../../components/upload';
import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import TextArea from 'antd/es/input/TextArea';
import { UploadChangeParam } from 'antd/lib/upload';
import { ProcedureTypeSelect } from '@/@core/modules/procedure/domain/procedure.entities';
import procedure from '@/@core/modules/procedure/infra/container.registry';
import { TMedicalProcedures } from '@/@core/modules/procedure/domain/procedure.entities';
import { useProcedure } from '../../context/procedure';
import { styles } from './styles';
import { TypeOfAppointment } from './components/typeOfAppointment';
import { DateHourLocation } from './components/dateHourLocation';
import { ProceduresComponentForm } from './components/procedures';
import { SelectedProcedures } from './components/selectedProcedures';
import { Information } from './components/infomations';
import { Patient } from './components/patient';
import { Responsible } from './components/responsible';
import { Address } from './components/address';
import moment from 'moment';
import ModalError from './components/modal';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Notification } from '@presentation/components/common';
import { ConfirmationView } from '../../components/confirmationView';
dayjs.extend(customParseFormat);

const Form = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [form] = AntForm.useForm<any>();
    const [formItemsOnConfirm, setFormItemsOnConfirm] = React.useState<any>();
    const [selectedProcedures, setSelectedProcedures] = React.useState<TMedicalProcedures[]>([]);
    const [isHealthInsurance, setIsHealthInsurance] = React.useState<boolean | null>(null);
    const [tissFileId, setTissFileId] = React.useState<number>();
    const [fileList, setFileList] = React.useState<any[]>([]);
    const [estimatedDuration, setEstimatedDuration] = React.useState<string>("");
    const [open, setOpen] = React.useState({
        open: false,
        text: '',
    })
    const [isConfirm, setIsConfirm] = React.useState(false);
    const [hasErrorPatientCpf, setHasErrorPatientCpf] = React.useState(false);
    const [hasErrorResponsibleCpf, setHasErrorResponsibleCpf] = React.useState(false);
    const [isSubmit, setIsSubmit] = React.useState(false);
    const [errorProcedure, setErrorProcedure] = React.useState(false);

    const {
        states,
        actions,
    } = useProcedure();


    const listProcedures = (type: ProcedureTypeSelect) => {
        // const selectedCodes = selectedProcedures.map((procedure) => procedure.code);
        return states && states.medicalProcedures.map((procedure) => {
            return {
                value: procedure.code,
                label: type === ProcedureTypeSelect.PROCEDURE ? procedure.name : procedure.code,
            };
        })
    };

    const handleProcedurePrincipal = (code: string) => {
        const newProcedures = procedure.medicalProcedure.setProcedurePrincipal(code, selectedProcedures);
        setSelectedProcedures(newProcedures);
    }

    const handleRemoveProcedure = (code: string) => {
        const procedureIndex = selectedProcedures.findIndex((procedure) => procedure.code === code);
        if (procedureIndex !== -1) {
            const newProcedures = [
                ...selectedProcedures.slice(0, procedureIndex),
                ...selectedProcedures.slice(procedureIndex + 1)
            ];
            calcEstimatedTimeSecond(newProcedures);

            const procedurePrincipal = newProcedures.find((procedure) => procedure.isPrincipal === true);
            if(!procedurePrincipal){
                newProcedures[0].isPrincipal= true
            }
            !isConfirm && setSelectedProcedures(newProcedures);
        }
        
    }

    const procedures = listProcedures(ProcedureTypeSelect.PROCEDURE);
    const tuss = listProcedures(ProcedureTypeSelect.TUSS);

    const calcEstimatedTimeSecond = (allProcedures: (TMedicalProcedures | undefined)[]) => {
        const maxEstimatedTimeSecond: number = allProcedures.reduce((max, procedure) => {
            return Math.max(max, procedure?.estimatedTimeSecond ?? 3600);
        }, 3600);

        const hours = Math.floor(maxEstimatedTimeSecond / 3600);
        const minutes = Math.floor((maxEstimatedTimeSecond % 3600) / 60);

        const timer = dayjs().set('hour', hours).set('minute', minutes);
        const formattedTime = timer.format('HH:mm');
        setEstimatedDuration(formattedTime);
        form.setFieldValue("durationEstimated", formattedTime)
    }

    const handleAddProcedure = (code: string, id?: string) => {
        let procedure;
        if (id) {
            procedure = states.medicalProcedures.find((procedure) => procedure.id === Number(id));
        } else {
            procedure = states.medicalProcedures.find((procedure) => procedure.code === code);
        }
        const procedureCopy = { ...procedure };
        const isFirstProcedure = selectedProcedures.length === 0;
        procedureCopy.isPrincipal = isFirstProcedure;
        const updatedProcedures = [...selectedProcedures, procedureCopy];

        setSelectedProcedures(updatedProcedures);
        form.resetFields(['tussCode', 'procedures']);
        calcEstimatedTimeSecond(updatedProcedures);
    };

    const handleSelectChargeType = (value: string) => {
        const checkIsHealthInsurance = value === 'health-insurance';
        setIsHealthInsurance(checkIsHealthInsurance);
    }

    const handleUploadChange = (info: UploadChangeParam, id: number) => {
        setTissFileId(id)
        if (info.file.status === 'error') {
            return 'File upload failed';
        }
    }

    const handleSearchAddress = (zip: string) => {
        const zipFormated = zip.replace(/\D/g, '')
        zipFormated.length >= 8 ? actions.handleSearchZipCode(zipFormated) : actions.handleSearchZipCode('', true)
    }

    const handleSearchPatientByCpf = () => {
        const formValues: any = {};
        form.resetFields([
            'patientFullName',
            'patientRg',
            'patientBirthDate',
            'patientGenre',
            'patientEmail',
            'patientPhone',
            'nationality',
            'patientInternationalDocument'
        ]);

        if (states.searchedPatient) {
            const fieldsToCheck = [
                { key: 'patientFullName', value: states.searchedPatient.name ?? undefined },
                { key: 'patientRg', value: states.searchedPatient.rg ?? undefined },
                { key: 'patientBirthDate', value: states?.searchedPatient?.birthday_date ? dayjs(states?.searchedPatient?.birthday_date) : undefined },
                { key: 'patientGenre', value: states.searchedPatient.gender ?? undefined },
                { key: 'patientEmail', value: states.searchedPatient.email ?? undefined },
                { key: 'patientPhone', value: states.searchedPatient.phone ?? undefined },
                { key: 'nationality', value: states.searchedPatient.nationality ?? undefined },
                { key: 'patientInternationalDocument', value: states.searchedPatient.international_document ?? undefined },
            ];

            for (const field of fieldsToCheck) {
                if (field.value) {
                    formValues[field.key] = field.value;
                }
            }
        } else {
            form.resetFields([
                'patientFullName',
                'patientRg',
                'patientBirthDate',
                'patientGenre',
                'patientEmail',
                'patientPhone',
                'nationality',
                'patientInternationalDocument'
            ]);
        }
    
        form.setFieldsValue(formValues);

        calcAge();
    };

    const calcAge = () => {
        const formItems = form.getFieldsValue()
        const birthDate = new Date(formItems.patientBirthDate);
        const today = new Date();
    
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();
    
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
    
        if (age >= 0) {
          form.setFieldValue("patientAge", age)
        }
      }
    const handleSearchGuardianByCpf = () => {
        const formValues: any = {};
        form.resetFields([
            'responsibleFullName',
            'responsibleRelationship',
            'responsibleEmail',
            'responsiblePhone'
        ]);
        // guardian
        if (states.searchedGuardian) {
            const fieldsToCheck = [
                { key: 'responsibleFullName', value: states.searchedGuardian.name ?? undefined },
                { key: 'responsibleRelationship', value: states.searchedGuardian.degree_of_kinship ?? undefined },
                { key: 'responsibleEmail', value: states.searchedGuardian.email ?? undefined },
                { key: 'responsiblePhone', value: states.searchedGuardian.phone ?? undefined },
            ];

            for (const field of fieldsToCheck) {
                if (field.value) {
                    formValues[field.key] = field.value;
                }
            }
        } else {
            form.resetFields([
                'responsibleFullName',
                'responsibleRelationship',
                'responsibleEmail',
                'responsiblePhone'
            ]);
        }
    
        form.setFieldsValue(formValues);
    };

    const handleSearchedAddress = () => {
        states.searchedAddress && form.setFieldsValue({
            addressStreet: states.searchedAddress.logradouro,
            addressNeighborhood: states.searchedAddress.bairro,
            addressCity: states.searchedAddress.localidade,
            addressState: states.searchedAddress.uf,
        })
    }

    const handleSubmit = async () => {
        setIsSubmit(true)
        const formItems = formItemsOnConfirm
        const schedulePaymentType  = typeof formItems.charge === 'object' ? formItems.charge.value : formItems.charge;

        const dateMoment = moment(formItems.procedureDate.toDate(), 'DD/MM/YYYY');
        const timeMoment = moment(formItems.procedureTime, 'HH:mm');
        const formatedDate = dateMoment.format('YYYY-MM-DD') + ' ' + timeMoment.format('HH:mm:ss.SSS ZZ');

        const procedures = selectedProcedures.map((item) => ({
            procedure_id: item.id,
            main: item.isPrincipal,
        }));
        const hasMainProcedure = procedures.some((procedure) => procedure.main === true);
        if (!hasMainProcedure && procedures.length > 0) {
            procedures[0].main = true;
        } else if (hasMainProcedure) {
            procedures.forEach((procedure, index) => {
                if (procedure.main && index !== procedures.findIndex((p) => p.main)) {
                    procedure.main = false;
                }
            });
        }
        
        const data: any = {
            schedule: {
                type: formItems?.type?.toUpperCase() || "SURGERY",
                schedule_date: formatedDate,
                unity_id: formItems.procedureLocation && Number(formItems.procedureLocation),
            },
            payment_type: Number(formItems.charge),
            schedule_payment: {
                total: formItems.price || null,
                schedule_payment_type_id: Number(schedulePaymentType) || null,
                password: formItems.healthInsurancePassword || null,
                health_plan: formItems.healthInsurancePlan || null,
                accomodation: formItems.healthInsuranceAccommodation || null,
                accommodation: formItems.healthInsuranceAccommodation || null,
                insurance_id: formItems.healthInsuranceName || null,
                code: formItems.healthInsuranceCardCode || null,
                observation: formItems.observation || null,
            },
            estimated_duration: formItems.durationEstimated,
            schedule_surgery: {},
            procedures: procedures,
            patient: {
                cpf: formItems.patientCpf,
                name: formItems.patientFullName,
                rg: formItems.patientRg,
                birthday_date: formItems.patientBirthDate,
                gender: formItems.patientGenre,
                nationality: formItems.nationality,
            },
            patient_contact: {
                phone: formItems.patientPhone,
                email: formItems.patientEmail,
                inertnational_document: formItems.patientInternationalDocument,
            },
            guardian: {
                cpf: formItems.responsibleCpf,
                name: formItems.responsibleFullName,
                degree_of_kinship: formItems.responsibleRelationship,
            },
            guardian_contact: {
                phone: formItems.responsiblePhone,
                email: formItems.responsibleEmail
            },
            address: {
                zipcode: formItems.addressZipCode,
                street: formItems.addressStreet,
                neighborhood: formItems.addressNeighborhood,
                number: formItems.addressNumber,
                complement: formItems.addressComplement,
                city: formItems.addressCity,
                state: formItems.addressState,
            },
            alergy: formItems.allergieAndPathologies,
            uploads: [
                ...fileList.map((item) => {
                    return {
                        id: item.id,
                        type: "DOCUMENT"
                    };
                })
            ]
        };
        
        const doctorId = typeof formItems?.doctorName === 'object' ? formItems?.doctorName?.value : formItems?.doctorName;
        data.schedule_surgery.surgeon_id = doctorId;

        const unityId = typeof formItems?.procedureLocation === 'object' ? formItems?.procedureLocation?.value : formItems?.procedureLocation;
        data.schedule.unity_id = unityId

        const paymentType = typeof formItems.charge === 'object' ? formItems?.charge?.value : formItems?.charge;
        data.payment_type = paymentType

        if (tissFileId) {
            data.uploads = [...data.uploads, { id: tissFileId, type: "TIIS" }]
        }

        if (id && states?.data?.schedule?.id) {
            data.schedule.id = states?.data?.schedule?.id
            data.uploads = [...data.uploads, ...states.data.uploads]
        }

        if (id && states?.data) {
            data.schedule_surgery.id = states?.data?.schedule_surgery?.id
            data.patient.id = states?.data?.patient?.id
            data.guardian.id = states?.data?.guardian?.id
            data.address.id = states?.data?.address?.id
        }

        if (states.searchedPatient?.id) {
            data.patient.id = states.searchedPatient?.id
        }

        if (states.searchedGuardian?.id){
            data.guardian.id = states.searchedGuardian?.id
        }

        let create = null;
        try {
            id && states?.data?.schedule?.id ? create = await procedure.update.execute(data) : create = await procedure.create.execute(data)
            form.resetFields()
            setFileList([])
            setTissFileId(undefined)
            if (create) {
                actions.handleSearchZipCode('', true)

                Notification.success({
                    message: `Procedimento agendado com sucesso! ID: ${create?.scheduleId}`,
                });            
                navigate(`/schedule`);
            }
            else {
                Notification.error({
                    message: `Falha ao agendar procedimento!`,
                });
            }
        } catch (error: any) {
            Notification.error({
                message: error.message,
            });
        }

    }

    const confirmDate = () => {
        const formItems = form.getFieldsValue()
        return procedure.create.scheduleDateValidationOnCreate(formItems.procedureDate.toDate())
    }

    const handleConfirm = async () => {
        try {
            await form.validateFields();
        } catch (error) {
            setOpen({
                open: true,
                text: 'Revise as informações fornecidas'
            })
            return;
        }

        if (hasErrorPatientCpf || hasErrorResponsibleCpf) {
            setOpen({
                open: true,
                text: 'Revise as informações fornecidas'
            })
        }

        if (selectedProcedures.length === 0) {
            setOpen({
                open: true,
                text: 'Você deve selecionar ao menos um procedimento'
            })
            setErrorProcedure(true)
            return;
        }

        const oldDate = moment(states?.data?.schedule?.schedule_date ?? undefined).format('DD/MM/YYYY');
        const newDate = moment(form.getFieldsValue().procedureDate.toDate()).format('DD/MM/YYYY');

        if (
            !id || (id && oldDate !== newDate)
        ) {
            const date = confirmDate();

            if (date.isError) {
                setOpen({
                    open: true,
                    text: date.text,
                });
                return;
            }
        }

        setFormItemsOnConfirm(form.getFieldsValue());

        setIsConfirm(true);
    };

    const handleOpen = (open: boolean, text = '') => {
        setOpen({ open, text })
    }

    const handleFileList = (fileList: UploadedType[]) => {
        setFileList(fileList)
    }

    const findSelectedItem = (arr: any[], id: string) => {
        return arr.find((item) => item.value === id)
    }

    const fetchData = async () => {
        setIsSubmit(false);
        form.setFieldValue("surgery", states?.data?.schedule?.type);
        form.setFieldValue("procedureDate", dayjs(states?.data?.schedule?.schedule_date ?? undefined));
        form.setFieldValue("procedureTime", dayjs(states?.data?.schedule?.schedule_date ?? undefined).format('HH:mm'));
        form.setFieldValue("procedureLocation", findSelectedItem(states.procedureLocation, String(states.data?.schedule?.unity_id)));
        form.setFieldValue("doctorName", findSelectedItem(states.doctors, String(states?.data?.schedule_surgery?.surgeon_id)));
        const procedureIds = states?.data?.procedures.map(procedure => procedure.procedure_id) || [];
        let selectedProcedures = procedureIds.map(id => {
            return states.medicalProcedures.find(procedure => procedure.id === Number(id));
        }).filter(Boolean);
        const procedurePrincipal = states?.data?.procedures.find(procedure => procedure.main === true);
        selectedProcedures = selectedProcedures.map(procedure => ({
            ...procedure,
            isPrincipal: false
        }));
        if (procedurePrincipal) {
            let first = false;
            selectedProcedures = selectedProcedures.map(procedure => {
                if (procedure.id === procedurePrincipal.procedure_id && !first) {
                    first = true; 
                    return {
                        ...procedure,
                        isPrincipal: true
                    };
                }
                return procedure;
            });
        } else  {
            selectedProcedures[0].isPrincipal = true;
        }
 
        // Atualiza os procedimentos selecionados
        setSelectedProcedures(selectedProcedures);
        setSelectedProcedures(selectedProcedures);
        form.setFieldValue("charge", findSelectedItem(states.chargeMethods, String(states?.data?.schedule_payment?.schedule_payment_type_id)));
        form.setFieldValue("healthInsuranceName", states?.data?.schedule_payment?.insurance_id);
        form.setFieldValue("healthInsurancePlan", states?.data?.schedule_payment?.health_plan);
        form.setFieldValue("healthInsuranceCardCode", states?.data?.schedule_payment?.code);
        form.setFieldValue("healthInsurancePassword", states?.data?.schedule_payment?.password);
        form.setFieldValue("healthInsuranceAccommodation", states?.data?.schedule_payment?.accommodation);
        form.setFieldValue("price", states?.data?.schedule_payment?.total);
        // patient
        form.setFieldValue("patientCpf", states?.data?.patient?.cpf);
        form.setFieldValue("patientFullName", states?.data?.patient?.name);
        form.setFieldValue("patientRg", states?.data?.patient?.rg);
        form.setFieldValue("patientBirthDate", dayjs(states?.data?.patient?.birthday_date ?? undefined));
        form.setFieldValue("patientGenre", states?.data?.patient?.gender)

        form.setFieldValue("patientEmail", states?.data?.patient_contact?.email)
        form.setFieldValue("patientPhone", states?.data?.patient_contact?.phone)
        form.setFieldValue("nationality", states?.data?.patient?.nationality)
        form.setFieldValue("patientInternationalDocument", states?.data?.patient_contact?.internation_document)
        // patient

        // guardian
        form.setFieldValue("responsibleCpf", states?.data?.guardian?.cpf);
        form.setFieldValue("responsibleFullName", states?.data?.guardian?.name);
        form.setFieldValue("responsibleRelationship", states?.data?.guardian?.degree_of_kinship);
        form.setFieldValue("responsibleEmail", states?.data?.guardian_contact?.email)
        form.setFieldValue("responsiblePhone", states?.data?.guardian_contact?.phone)
        // guardian

        // address
        form.setFieldValue("addressZipCode", states?.data?.address?.zipcode)
        form.setFieldValue("addressStreet", states?.data?.address?.street)
        form.setFieldValue("addressNeighborhood", states?.data?.address?.neighborhood)
        form.setFieldValue("addressNumber", states?.data?.address?.number)
        form.setFieldValue("addressComplement", states?.data?.address?.complement)
        form.setFieldValue("addressCity", states?.data?.address?.city)
        form.setFieldValue("addressState", states?.data?.address?.state)
        // address

        form.setFieldValue("allergieAndPathologies", states?.data?.alergy)
        form.setFieldValue("observation", states.data?.schedule_payment?.observation)
        
        // Estimated time
        //console.log(states.data?.estimated_duration)
        form.setFieldValue("durationEstimated", states.data?.estimated_duration)

        const getTiss = states?.data?.uploads?.filter((item: any) => item.type === "TIIS")
        setTissFileId(getTiss?.id)
    };

    React.useEffect(() => {
        if (!id) {
            form.resetFields()
            setFileList([])
            setTissFileId(undefined)
            return;
        }
        fetchData();
    }, [id, states.data]);

    React.useEffect(() => {
        actions.handleInitLoadSearchGeneral(id)
    }, [id])

    React.useEffect(() => {
        handleSearchedAddress()
    }, [states.searchedAddress?.logradouro])

    React.useEffect(() => {
        handleSearchPatientByCpf()
    }, [states.searchedPatient?.name])

    React.useEffect(() => {
        handleSearchGuardianByCpf()
    }, [states.searchedGuardian?.name])


    return (
        <div style={{ width: '96%', margin: 10 }}>
            <ModalError onOk={handleOpen} open={open.open} text={open.text} />
            <Loading value={states.loading}>
                <AntForm
                    name="form-user-edit"
                    form={form}
                    layout="vertical"
                    autoComplete="off"
                    style={{ margin: 30 }}
                >
                    <h1 style={styles.h1}>{isConfirm ? 'Dados para conferência' : 'Procedimento'}</h1>
                    <TypeOfAppointment form={form} states={states} isConfirm={isConfirm} />
                    <Divider />
                    <DateHourLocation form={form} states={states} isConfirm={isConfirm} />
                    <Divider />
                    <ProceduresComponentForm
                        form={form}
                        isConfirm={isConfirm}
                        handleAddProcedure={handleAddProcedure}
                        tuss={tuss}
                        procedures={procedures}
                        doctors={states.doctors}
                    />
                    <Divider />
                    <SelectedProcedures
                        isConfirm={isConfirm}
                        selectedProcedures={selectedProcedures}
                        handleProcedurePrincipal={handleProcedurePrincipal}
                        handleRemoveProcedure={handleRemoveProcedure}
                        errorProcedure={errorProcedure}
                    />
                    <Divider />
                    <Information
                        form={form}
                        isConfirm={isConfirm}
                        states={states}
                        handleSelectChargeType={handleSelectChargeType}
                        handleUploadChange={handleUploadChange}
                        isHealthInsurance={isHealthInsurance}
                    />
                    <h1 style={styles.h1}>Paciente</h1>
                    <Typography style={styles.typography}>Dados do paciente</Typography>
                    <Patient
                        handleSearchPatientByCpf={actions.handleFindPatientByCpf}
                        isConfirm={isConfirm}
                        states={states}
                        form={form}
                        setHasError={setHasErrorPatientCpf}
                        changehPatient={handleSearchPatientByCpf}
                        calcAge={calcAge}
                    />
                    <Divider />
                    <Typography style={styles.typography}>Responsável legal</Typography>
                    <Responsible 
                        handleSearchResponsibleByCpf={actions.handleFindGuardianByCpf}
                        isConfirm={isConfirm} 
                        states={states} 
                        form={form} 
                        setHasError={setHasErrorResponsibleCpf}
                        changeGuardian={handleSearchGuardianByCpf}                             
                    /><Divider />
                    <Typography style={styles.typography}>Endereço</Typography>
                    <Address form={form} isConfirm={isConfirm} states={states} searchAddress={handleSearchAddress} />
                    <Divider />
                    <UploadComponent states={states} isConfirm={isConfirm} name="filesUploaded" handleFileList={handleFileList} />
                    {isConfirm ? (
                        <ConfirmationView item={{
                            label: 'Observações',
                            value: form.getFieldsValue().observation || ''
                        }} />
                    ) : (
                        <AntForm.Item name="observation" label="Observações">
                            <TextArea
                                disabled={isConfirm}
                                placeholder="Observações..."
                                style={{ ...styles.defaultInput, borderRadius: 8 }}
                                autoSize={{ minRows: 5, maxRows: 8 }}
                            />
                        </AntForm.Item>
                    )
                    }
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            width: '100%',
                        }}
                    >
                        {
                            isConfirm ? (
                                <div>
                                    <Button text="Voltar" nativeProps={{
                                        style: {
                                            marginBottom: 10,
                                            marginLeft: 30,
                                        },
                                    }}
                                        onClick={() => setIsConfirm(false)}
                                    />
                                    <Button text="Finalizar agendamento" nativeProps={{
                                        style: {
                                            marginBottom: 10,
                                            marginLeft: 30,
                                        },
                                    }}
                                        disabled={isSubmit}
                                        onClick={() => handleSubmit()}
                                    />
                                </div>
                            ) : (
                                <Button text="Avançar" nativeProps={{
                                    style: {
                                        marginBottom: 10,
                                        marginLeft: 30,
                                    },
                                }}
                                    onClick={() => handleConfirm()}
                                />
                            )
                        }
                    </div>
                </AntForm>
            </Loading>
        </div>
    );
};

export default Form;
