import React from 'react';
import {Layout as AntLayout, theme} from 'antd';
import authentication from '@presentation/modules/route/authentication';
import Prohibited from '@presentation/modules/route/pages/prohibited';
import Header from './header';
import Sidebar from './sidebar';

import {hasPermission, ENUM_PERMISSIONS} from '@presentation/modules/route/helpers';

interface ILayout {
    permission: ENUM_PERMISSIONS[];
    children: React.ReactNode;
}

const Layout: React.FC<ILayout> = (props: ILayout) => {
    const {
        token: {
            colorBgLayout,
        },
    } = theme.useToken();

    const [open, setOpen] = React.useState(false);
    const [collapsed, setCollapsed] = React.useState(false);
    const [isSmall, setIsSmall] = React.useState(false);
    const defaultSelectedKeys = authentication.defaultRoute.key;
    const [isPermitted, setIsPermitted] = React.useState(true);

    React.useEffect(() => {
        const getPermission = async () => {
            setIsPermitted(await hasPermission(props.permission));
        };
        getPermission();
    }, []);

    return (
        <AntLayout
            style={{
                minHeight: '100vh',
                maxHeight: '100vh',
                minWidth: '100%',
                maxWidth: '100%',
            }}
        >
            <Sidebar
                defaultSelectedKeys={defaultSelectedKeys}
                collapsed={collapsed}
                setCollapsed={setCollapsed}
                setIsSmall={setIsSmall}
                isSmall={isSmall}
                setOpen={setOpen}
                open={open}
            />
            <AntLayout
                style={{
                    paddingRight: 34,
                    backgroundColor: colorBgLayout,
                    paddingBottom: 38,
                    overflow: 'auto',
                }}
            >
                <Header isSmall={isSmall} setOpen={setOpen} open={open} />
                {isPermitted ? (
                    <div
                        style={{
                            minWidth: '100%',
                            maxWidth: '100%',
                            minHeight: '90%',
                            maxHeight: '90%',
                            padding: 20,
                            marginLeft: isSmall ? '20px' : '0px',
                        }}
                    >
                        {props.children}
                    </div>
                ) : (
                    <Prohibited />
                )}
            </AntLayout>
        </AntLayout>
    );
};

export default Layout;
