import { AxiosInstance } from "axios";
import { IChartNextDays, ISimpleChart } from "../domain/dashboard.entities";
import { DashboardGateway } from "../domain/dashboard.gateway";
import { Paths } from './keys';

export class DashboardHttpGateway implements DashboardGateway {
    constructor(private http: AxiosInstance) { }

    async getSchedulesNext30Days(): Promise<IChartNextDays[]> {
        return await this.http.get<IChartNextDays[]>(Paths.scheduleNext30Days).then((response) => response.data);
    }

    async getSchedulesToday(): Promise<ISimpleChart> {
        return await this.http.get<ISimpleChart>(Paths.scheduleToday).then((response) => response.data);
    }

    async getSchedulesYesterday(): Promise<ISimpleChart> {
        return await this.http.get<ISimpleChart>(Paths.scheduleYesterday).then((response) => response.data);
    }

    async pendingToConfirmation(): Promise<ISimpleChart> {
        return await this.http.get<ISimpleChart>(Paths.schedulePendingToConfirmation).then((response) => response.data);
    }

    async pendingToPay(): Promise<ISimpleChart> {
        return await this.http.get<ISimpleChart>(Paths.schedulePendingToPay).then((response) => response.data);
    }
}