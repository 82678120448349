import { ConciliatorFilter } from "@/@core/modules/conciliator/domain/conciliator.entity";
import { Form, Select, DatePicker, Row, Col } from "antd";
const { Option } = Select;
import { FormInstance } from "antd/lib";
import React from "react";

enum ENUM_PAYMENT_TYPE {
    CREDIT_CARD = 'CREDIT_CARD',
    TICKET_AND_PIX = 'BANK_SLIP_AND_PIX',
}

const CreditCard: React.FC<{
    form: FormInstance<ConciliatorFilter>;
}> = ({ form }) => {
    return (
        <>
            <Row gutter={16}>
                <Col span={3}>
                    <Form.Item
                        name="initialTransactionDate"
                        label="Data Transação De"
                        labelCol={{ span: 24 }}
                    >
                        <DatePicker style={{ width: '100%', maxWidth: '300px', borderRadius: 8 }} />
                    </Form.Item>
                </Col>
                <Col span={3}>
                    <Form.Item
                        name="finalTransactionDate"
                        label="Data Transação Até"
                        labelCol={{ span: 24 }}
                    >
                        <DatePicker style={{ width: '100%', maxWidth: '300px', borderRadius: 8 }} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        name="companyName"
                        label="Empresa"
                        labelCol={{ span: 24 }}
                    >
                        <Select placeholder="Selecione uma empresa">
                            <Option value="GADP">GADP</Option>
                            <Option value="REDE">REDE</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        name="adquirente"
                        label="Adquirente"
                        labelCol={{ span: 24 }}
                    >
                        <Select placeholder="Selecione um adquirente">
                            <Option value="VINDI">VINDI</Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        </>
    );
}

const TicketAndPix: React.FC<{
    form: FormInstance<ConciliatorFilter>;
}> = ({ form }) => {
    return (
        <>
            <Row gutter={16}>
                <Col span={6}>
                    <Form.Item
                        name="initialTransactionDate"
                        label="Data"
                        labelCol={{ span: 24 }}
                    >
                        <DatePicker style={{ width: '100%', maxWidth: '300px', borderRadius: 8 }} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        name="companyName"
                        label="Empresa"
                        labelCol={{ span: 24 }}
                    >
                        <Select placeholder="Selecione uma empresa">
                            <Option value="GADP">GADP</Option>
                            <Option value="REDE">REDE</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        name="bank"
                        label="Banco"
                        labelCol={{ span: 24 }}
                    >
                        <Select placeholder="Selecione um banco">
                            <Option value="VINDI">VINDI</Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        </>
    );
}

const ConcilitorFilters: React.FC<{
    selectedPaymentType: string;
    setSelectedPaymentType: (value: "CREDIT_CARD" | "BANK_SLIP_AND_PIX" | "SELECT") => void;
    form: FormInstance<ConciliatorFilter>;
}> = (props) => {
    const {  form } = props;

    const optionsPaymentType = [
        { value: 'SELECT', label: 'Selecione' },
        { value: ENUM_PAYMENT_TYPE.CREDIT_CARD, label: 'Cartão de Crédito' },
        { value: ENUM_PAYMENT_TYPE.TICKET_AND_PIX, label: 'Boleto e PIX' },
    ];

    return (
        <Form form={form}>
            <Col span={6}>
                <Form.Item name="paymentType">
                    <Row style={{ marginBottom: 10}}>
                        <label>Tipo de Pagamento</label>
                    </Row>
                    <Select
                        options={optionsPaymentType}
                        defaultValue={optionsPaymentType[0].value}
                        value={props.selectedPaymentType}
                        onChange={(e) => props.setSelectedPaymentType(e as "CREDIT_CARD" | "BANK_SLIP_AND_PIX" | "SELECT")}
                        style={{ width: 170 }}
                    />
                </Form.Item>
            </Col>

            {props.selectedPaymentType === ENUM_PAYMENT_TYPE.CREDIT_CARD && <CreditCard form={form} />}
            {props.selectedPaymentType === ENUM_PAYMENT_TYPE.TICKET_AND_PIX && <TicketAndPix form={form} />}
        </Form>
    );
}

export default ConcilitorFilters;
