export type UserType = {
    id: number;
    email: string;
    name: string;
    area: string | null;
    occupation: string | null;
    rg: string | null;
    cpf: string | null;
    type: string;
    nationality: string | null;
    birthdayDate: string | null;
    gender: string;
    hasChildren: boolean | null;
    accessId: number;
    imageUploadId: number | null;
    juridicalPersonId: number | null;
    createdAt: string;
    updatedAt: string;
    profiles: ProfileType[];
};

export type ProfileType = {
    id: number;
    name: string;
    createdAt: string;
    updatedAt: string;
    deletedAt: string | null;
    permissions: PermissionType[];
};

export type PermissionType = {
    id: number;
    key: string;
    module: string;
    createdAt: string;
    updatedAt: string;
    deletedAt: string | null;
};

export type TIsPermitted = {
    path?: string;
    role?: 'read' | 'view' | 'write' | 'delete' | 'test';
};

export class UserInfos {
    constructor(public props: UserType) {}

    get id() {
        return this.props.id;
    }
    get email() {
        return this.props.email;
    }
    get name() {
        return this.props.name;
    }
    get type() {
        return this.props.type;
    }
    get accessId() {
        return this.props.accessId;
    }
    get profiles() {
        return this.props.profiles;
    }

    toJSON() {
        return {
            id: this.id,
            email: this.email,
            name: this.name,
            type: this.type,
            accessId: this.accessId,
            profiles: this.profiles,
        };
    }
}
