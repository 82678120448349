export const CreditCardColumn = [
    {
      title: '',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Identificado',
      dataIndex: 'identified',
      key: 'identified',
    },
    {
      title: 'Não Identificado',
      dataIndex: 'notIdentified',
      key: 'notIdentified',
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
    },
];

export const TicketAndPixColumn = [
  {
    title: '',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Identificado',
    dataIndex: 'identified',
    key: 'identified',
  },
];

export const RightColumn = [
  {
    title: 'Já processado',
    dataIndex: 'processed',
    key: 'processed',
  },
  {
    title: 'Não Conciliado',
    dataIndex: 'notReconciled',
    key: 'notReconciled',
  },
];