import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Spin, theme } from 'antd';
import style from './style';
import { hasPermission, paths } from '@presentation/modules/route/helpers';
import authentication from '@presentation/modules/route/authentication';

interface IMenu {
    isSmall: boolean;
    collapsed: boolean;
    selectItem: string;
    setSelectItem: React.Dispatch<React.SetStateAction<string>>;
}

const Menu: React.FC<IMenu> = (props: IMenu) => {
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const navigate = useNavigate();
    const [items, setItems] = React.useState<any>([]);

    const {
        token: { colorPrimary, colorText },
    } = theme.useToken();

    const checkPermission = async (path: any[]) => {
        path.map(async (item) => {
            const permission = item.permission;
            if (permission) {
                const isPermitted = await hasPermission(permission);
                if (isPermitted) {
                    setItems((prev: any) => [...prev, item]);
                }
            }
        }
        );
    }

    const initialized = async () => {
        setItems([]);
        checkPermission(paths(authentication?.paths));
        props.setSelectItem(window.location.pathname);
        setIsLoading(true);
        setTimeout(() => {
            setIsLoading(false);
        }, 2000);
    };

    React.useEffect(() => {
        initialized();
    }, []);

    const handleNavigate = (key: string) => {
        props.setSelectItem(key);
        navigate(key);
    };

    function modifySvgFilename(filename: string, suffix: string) {
        if (filename.endsWith('.svg')) {
            const lastIndex = filename.lastIndexOf('.svg');
            return filename.substring(0, lastIndex) + suffix + filename.substring(lastIndex);
        }

        return filename;
    }

    const Menu = () => {
        if (isLoading) {
            return <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '50vh',
            }}>
                <Spin size='large' />
            </div>
        }
        return (
            <>
                {
                    items?.map((item: any) => {
                        const isSelected = item.key === props.selectItem;

                        return (
                            <span
                                key={item.key}
                                tabIndex={0}
                                style={{
                                    marginLeft: '10%',
                                    display: 'block',
                                    opacity: 1,
                                    cursor: 'pointer',
                                    color: isSelected ? 'white' : colorText,
                                    fontWeight: 400,
                                    fontSize: 14,
                                    lineHeight: 1.5,
                                    backgroundColor: isSelected ? colorPrimary : 'transparent',
                                    padding: 7,
                                    margin: 5,
                                    borderRadius: isSelected ? '10px' : '0',
                                }}
                                onClick={() => handleNavigate(item.key)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleNavigate(item.key);
                                    }
                                }}
                            >
                                {item.icon && <img
                                    src={isSelected ? modifySvgFilename(item.icon, '-white') : modifySvgFilename(item.icon, '-grey')}
                                    style={{
                                        cursor: 'not-allowed',
                                        height: 20,
                                        width: 20,
                                        color: isSelected ? 'white' : colorText,
                                        marginRight: 10,
                                    }}
                                />} {item.label}
                            </span>
                        );
                    })

                }
            </>
        );
    };

    return (
        <div style={{ marginTop: 35 }}>
            <Menu />
        </div>
    );
};

export default Menu;
