import React from 'react';
import { Flex, Form, Row, Col } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Button, InputEmail, InputPassword, validateMessages } from '../../../../components/form';

import { TypeRequestAccessParams } from '@core/modules/authentication/domain/requestAccess.entities';
import { useAuthentication } from '@presentation/modules/authentication/contexts/authentication';
import { ForgotPassword } from '../../components/forgotPassword';
import { ComponentTermsOfUseAndPrivacyPolicies } from '../../components/termsOfUseAndPrivacyPolicies';
import { auth } from '@core/modules/authentication/infra/container.registry';
import authentication from '@presentation/modules/route/authentication';

import style from './style';

const Login = () => {
    const navigate = useNavigate();
    const { signIn } = useAuthentication();
    const [loading, setLoading] = React.useState(false);
    const [visibleTermsOfUseAndPrivacyPolicies, setVisibleTermsOfUseAndPrivacyPolicies] =
        React.useState(false);

    const initialValuesDev = {
        // email: 'thiagogladstone@gmail.com',
        // password: '123456789',
    };

    const handleLogin = async (values: TypeRequestAccessParams) => {
        try {
            setLoading(true);
            const isLogin = await signIn(values);

            if (isLogin?.access_token) {
                const acceptLastTerm = await auth.acceptLastTerm.execute();
                if (!acceptLastTerm) {
                    setVisibleTermsOfUseAndPrivacyPolicies(true);
                } else {
                    setTimeout(async () => {
                        navigate(authentication.defaultRoute.key);
                        setLoading(false);
                    }, 1500);
                }
            }
        } finally {
            setTimeout(async () => {
                setLoading(false);
            }, 4000);
        }
    };

    return (
        <div style={style.div}>
            {visibleTermsOfUseAndPrivacyPolicies && (
                <ComponentTermsOfUseAndPrivacyPolicies
                    visible={visibleTermsOfUseAndPrivacyPolicies}
                    setVisible={setVisibleTermsOfUseAndPrivacyPolicies}
                />
            )}

            <Row style={style.row}>
                <Col xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 12 }} style={{ position: 'relative' }}>
                    <img
                        alt="bg2"
                        src="/background2.svg"
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '90%',
                            height: '100%',
                            zIndex: 1,
                        }}
                    />
                    <img
                        alt="background"
                        src="/background.svg"
                        style={{
                            position: 'relative',
                            width: '100%',
                            height: '100%',
                            zIndex: 2,
                        }}
                    />
                </Col>
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    style={{
                        ...style.col,
                        ...style.colSecond,
                        backgroundColor: '#054b7c',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <img
                        alt="bg2"
                        src="/background3.svg"
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 90,
                            width: '90%',
                            height: '100%',
                            zIndex: 2,
                        }}
                    />
                    <div style={{...style.card, zIndex: 2}}>
                        <img
                            alt="tk"
                            src="/tk.svg"
                            style={{ margin: 'auto', marginTop: 40 }}
                            height={46}
                            width={126}
                        />
                        <span style={style.cardTitle}>BEM-VINDO(A) AO PORTAL DO CIRURGIÃO</span>
                        <span style={{ fontSize: 14, fontWeight: 300, color: '#475569' }}>
                            Nossa parceria cada vez mais simplificada!
                        </span>
                        <span
                            style={{
                                ...style.cardSubTitle,
                                marginTop: '3.5%',
                            }}
                        >
                            Acesse suas notas fiscais e, em breve, agende procedimentos com
                            facilidade.
                        </span>

                        <div style={{
                            padding: 32,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: '100%',
                        }}>
                            <Form
                                name="normal_login"
                                className="login-form"
                                labelCol={{ span: 12 }}
                                layout="vertical"
                                validateMessages={validateMessages}
                                initialValues={initialValuesDev}
                                onFinish={handleLogin}
                                autoComplete="off"
                                style={{
                                    marginTop: '1%',
                                    width: '100%',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <InputEmail label="E-mail" placeholder="Insira seu e-mail" required={true} style={{ maxWidth: 415, marginBottom: 24 }} />
                                <InputPassword label="Senha" placeholder="*****" required={true} style={{ maxWidth: 415, marginBottom: 6 }} />
                                <Row
                                    style={{
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                        justifyContent: 'flex-end'
                                    }}
                                >
                                    <ForgotPassword />
                                </Row>
                                <Flex justify="center" style={style.cardFormFlex}>
                                    <Button
                                        text='Entrar'
                                        nativeProps={{
                                            htmlType: 'submit',
                                            style: {
                                                width: '100%'
                                            }
                                        }}
                                        className="login-form-button"
                                        loading={loading}
                                    />
                                </Flex>
                            </Form>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default Login;
