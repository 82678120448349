import {useState} from 'react';
import UserList from '../user';
import SurgeonList from '../surgeon';

const tabs = [
    {key: 'user', label: 'Usuários'},
    {key: 'surgeon', label: 'Cirurgião'},
];

const Administration = () => {
    const [activeTab, setActiveTab] = useState('user');
    const handleTabClick = (tabKey: string) => {
        setActiveTab(tabKey);
    };

    const handleTabKeyPress = (event: any, tabKey: string) => {
        if (event.key === 'Enter') {
            handleTabClick(tabKey);
        }
    };

    const renderContent = () => {
        switch (activeTab) {
            case 'user':
                return <UserList />;
            case 'surgeon':
                return <SurgeonList />;
            default:
                return null;
        }
    };

    return (
        <div data-testid="layout-administration">
            <div
                style={{
                    display: 'flex',
                    marginBottom: '10px',
                }}
            >
                {tabs.map((tab) => (
                    <div
                        key={tab.key}
                        onClick={() => handleTabClick(tab.key)}
                        onKeyPress={(e) => handleTabKeyPress(e, tab.key)}
                        role="button"
                        tabIndex={0}
                        style={{
                            padding: '10px',
                            cursor: 'pointer',
                            fontWeight: activeTab === tab.key ? 500 : 400,
                            color: activeTab === tab.key ? 'black' : 'lightgray',
                            fontSize: activeTab === tab.key ? 40 : 34,
                            marginTop: activeTab === tab.key ? 0 : 5,
                            letterSpacing: 0.73,
                        }}
                    >
                        {tab.label}
                    </div>
                ))}
            </div>

            {renderContent()}
        </div>
    );
};

export default Administration;
