import React from 'react';
import { Modal as ModalAntd } from 'antd';

interface IModal {
  footer: React.ReactNode,
  open: boolean,
  title: string,
  children: React.ReactNode,
}

const Modal: React.FC<IModal> = (props) => {
  return (
    <>
      <ModalAntd
        open={props.open}
        title={props.title}
        footer={props.footer}
      >
        {props.children}
      </ModalAntd>
    </>
  );
};

export default Modal;
